<!--<template>
  <div>
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-8 mx-auto bg-white rounded shadow">
          <v-card>
            <v-card-title>
              My Resells
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="transactionsHeaders"
              :items="transactionArray"
              :search="search"
              @click:row="transactionClicked"
            >
              <template v-slot:item.timestamp="{ item }">
                {{ getTimeStamp(item.timestamp) }}
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
-->

<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          <div style="padding: 10px" v-bind:class="titleClass">
            {{ tableTitle }}
            <v-spacer></v-spacer
            ><v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Date Range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                scrollable
                no-title
                @change="onDateSelected()"
                range
              ></v-date-picker>
            </v-menu>
            <v-btn
              class="
                mx-1
                subtitle-1
                white--text
                font-weight-regular
                text-capitalize
              "
              color="teal darken-4"
              medium
              @click="searchTxns()"
              ><v-icon dark> fa fa-search</v-icon></v-btn
            >
            <v-btn
              class="
                mx-1
                subtitle-1
                white--text
                font-weight-regular
                text-capitalize
              "
              @click="download()"
              :disabled="grantArray.length === 0"
              color="light-blue darken-4"
              ><v-icon dark> mdi-cloud-download</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-card-title>
        <v-layout v-resize="onResize" column>
          <v-data-table
            :headers="grantsHeaders"
            :items="grantArray"
            :search="search"
            disable-pagination
            :hide-default-footer="true"
            :hide-default-header="isMobile"
            :class="{ mobile: isMobile }"
            :loading="isLoading"
          >
            <template v-slot:body="props">
              <tbody v-if="!isMobile">
                <tr v-for="item in props.items" :key="item.id">
                  <td class="d-block d-sm-table-cell">
                    {{ getTimeStamp(item.timestamp) }}
                  </td>
                  <td class="d-block d-sm-table-cell">
                    {{ getMcValue(item.mfValue) }}
                  </td>
                  <td class="d-block d-sm-table-cell">
                    {{ item.id }}
                  </td>
                  <td class="d-block d-sm-table-cell">
                    <v-chip :color="getStatusColor(item.status)" dark>
                      {{ item.status }}
                    </v-chip>
                  </td>
                  <td class="d-block d-sm-table-cell">
                    {{ getGranterText(item) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="isMobile">
                <tr v-for="item in props.items" :key="item.id">
                  <td>
                    <ul class="flex-content">
                      <li class="flex-item" data-label="Timestamp">
                        <strong>Timestamp</strong><br />
                        {{ getTimeStamp(item.timestamp) }}
                      </li>
                      <li class="flex-item" data-label="MF Credits">
                        <strong>MF Credits</strong><br />
                        {{ getMcValue(item.mfValue) }}
                      </li>
                      <li class="flex-item" data-label="Timestamp">
                        <strong>Payment ID</strong><br />
                        {{ item.id }}
                      </li>
                      <li class="flex-item" data-label="Status">
                        <strong>Status</strong><br />
                        <v-chip :color="getStatusColor(item.status)">
                          {{ item.status }}
                        </v-chip>
                      </li>
                      <li class="flex-item" data-label="Grant by">
                        <strong>Grant by</strong><br />
                        {{ getGranterText(item) }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-layout>
        <v-pagination
          v-model="pageNo"
          :length="totalPagesCount"
          total-visible="7"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="pageChanged"
        ></v-pagination>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "../utils/SageUtils";
export default {
  name: "ResellerCreditGrants",
  props: {
    grants: {
      type: Array,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "Credit Grants",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    grants: function (newVal, oldVal) {
      this.grantArray = newVal;
    },
    totalPages: function (newVal, oldVal) {
      this.totalPagesCount = newVal;
    },
    page: function (newVal, oldVal) {
      this.pageNo = newVal;
    },
  },
  data() {
    return {
      grantArray: [],
      search: "",
      isMobile: false,
      grantsHeaders: [
        {
          text: "Date/Time",
          align: "start",
          value: "timestamp",
        },
        { text: "MF Credits", value: "mfValue" },
        { text: "Payment ID", value: "id" },
        { text: "Status", value: "status" },
        { text: "Grant by", value: "granter" },
      ],
      pageNo: 1,
      totalPagesCount: 0,
      tableTitle: "",
      dates: [
        SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
        SageUtils.getDateFromToday(0),
      ],
      menu: false,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join("~");
    },
    titleClass() {
      if (this.isMobile) return "col";
      return "row";
    },
  },
  mounted() {
    this.grantArray = this.grants;
    this.pageNo = this.page;
    this.totalPagesCount = this.totalPages;
    this.tableTitle = this.title;
  },
  methods: {
    download() {
      this.$emit("download", this.dates);
    },
    searchTxns() {
      this.$emit("search", this.dates);
    },
    getTimeStamp(timestamp) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(timestamp).toLocaleString("en-IN", {
        timeZone: tz,
      });
    },
    pageChanged(value) {
      this.$emit("pageChanged", { pageNo: value, dates: this.dates });
    },
    getMcValue(mcValue) {
      if (!mcValue) {
        return "NA";
      }
      return mcValue.toString();
    },
    getStatusColor(status) {
      if (status === SageConstants.paymentConstants.SUCCESS) return "green";
      else if (status === SageConstants.paymentConstants.FAILED) return "red";
      else return "ffc107";
    },
    getGranterText(item) {
      if (item.resellerId) return "You";
      return "Moonfrog";
    },
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    onDateSelected() {
      if (!this.dates) return;
      this.dates.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      if (this.dates.length < 2) return;
      const dateStart = new Date(this.dates[0]);
      const dateEnd = new Date(this.dates[1]);
      const diffTime = Math.abs(dateEnd - dateStart);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > SageConstants.MAX_DATE_RANGE) {
        this.dates = [
          SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
          SageUtils.getDateFromToday(0),
        ];
        this.$swal.fire({
          title: "Error",
          icon: "error",
          text: `Please select the date range within ${SageConstants.MAX_DATE_RANGE} days`,
        });
      }
    },
  },
};
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.07) !important;
}
ul {
  padding: 0px;
}
.mobile table.v-table tr td ul li:before {
  content: attr(data-label);
  padding-right: 0.5em;
  text-align: left;
  display: block;
  color: #999;
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  width: 50%;
}
.mobile table.v-table tr td {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  height: auto;
}
</style>