<template>
  <div>
    <div class="main-content">
      <div class="header" style="padding-bottom: 8rem !important">
        <ResellerHeader :walletCredits="credits" />
      </div>
      <!-- Page content -->
      <div class="container-fluid mt--7">
        <div class="row">
          <!-- Register Sub Resellers Card -->
          <div class="col-xl-4 order-xl-2 mb-xl-0">
            <div class="card card-profile shadow">
              <div class="card-body pt-0 pt-md-4">
                <!-- Heading -->
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        card-profile-stats
                        d-flex
                        justify-content-center
                        mt-md-0
                      "
                    >
                      <div>
                        <span class="heading">Transfer credits</span>
                        <span class="description"
                          >Transfer your MF Credits to
                          {{ subResellerName }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Form -->
                <div class="text-center">
                  <form id="credit-transfer-form" action="#">
                    <div class="row">
                      <div class="input-group col-lg-12 mb-4">
                        <input
                          id="creditsToTransfer"
                          class="form-control bg-white border-md"
                          type="number"
                          placeholder="Enter Credits to transfer"
                          v-model="creditsToTransfer"
                          @input="convertToInteger"
                        />
                      </div>
                      <!-- Submit Button -->
                      <ActionButton
                        id="submit-btn"
                        text="Transfer"
                        @onclick="transferMfCredits"
                        class="mb-4"
                        style="flex: 0 0 40%; max-width: 40%"
                      />
                    </div>
                  </form>

                  <!-- <hr class="my-4" /> -->
                  <!-- <p>
                    By clicking Submit, you agree to the Moonfrog Labs
                    <a
                      href="https://moonfroglabs.com/terms-of-service/"
                      target="_blank"
                      >Terms of Service</a
                    >,
                    <a
                      href="https://moonfroglabs.com/privacy-policy-2/"
                      target="_blank"
                      >Privacy Policy</a
                    >
                    and
                    <router-link
                      :to="{ name: 'distributorTnC' }"
                      target="_blank"
                    >
                      Terms &amp; Conditions</router-link
                    >, that you may be bound by creating this account with
                    Moonfrog Labs.
                  </p> -->
                </div>
              </div>
            </div>
          </div>

          <!-- Left Side View -->
          <div class="col-xl-8 order-xl-1" style="padding-bottom: 2rem">
            <div class="card bg-secondary shadow mb-4">
              <div class="card-body">
                <form>
                  <div class="row">
                    <h6 class="heading-small text-muted mb-0 mr-5">
                      {{ subResellerType }} info
                    </h6>
                    <div v-if="!isLoading">
                      <v-chip :color="getStatusColor(subResellerStatus)" dark>
                        {{ subResellerStatus }}
                      </v-chip>
                    </div>
                  </div>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-username"
                            >Name</label
                          >
                          <input
                            type="text"
                            id="input-username"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="subResellerName"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-email"
                            >Email address</label
                          >
                          <input
                            type="email"
                            id="input-email"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="$route.params.email"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-username"
                            >MF Credits</label
                          >
                          <input
                            type="text"
                            id="input-mfcredits"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="subResellerCredits"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-username"
                            >Phone Number</label
                          >
                          <input
                            type="text"
                            id="input-phone"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="subResellerPhoneNumber"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 mb-0">
                        <div class="form-group focused mb-0">
                          <label class="form-control-label" for="input-username"
                            >Date Of Birth</label
                          >
                          <input
                            type="text"
                            id="input-dob"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="subResellerDob"
                            readonly
                          />
                        </div>
                      </div>
                      <div
                        class="col-lg-3 mb-0"
                        style="
                          margin-left: auto;
                          margin-right: auto;
                          margin-top: auto;
                          margin-bottom: auto;
                        "
                        v-if="!isLoading && subResellerStatus === 'ACTIVE'"
                      >
                        <button
                          id="deactivate-btn"
                          type="button"
                          class="btn btn-primary btn-lg btn-block text-truncate"
                          @click="requestDeactivation"
                        >
                          Request Deactivation
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- Subreseller Cards -->
            <v-expansion-panels multiple>
              <v-expansion-panel
                @click.native="rechargesPanelClicked"
                class="mb-4"
              >
                <v-expansion-panel-header
                  >{{ subResellerType }} Transactions
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ResellerPlayerTransactions
                    :isLoading="!rechargeTableLoaded"
                    :transactions="playerRecharges"
                    @pageChanged="rechargeTablePageChanged"
                    :page="rechargeTablePage"
                    :totalPages="rechargeTableTotalPages"
                    title="Transactions"
                    @download="downloadRechargeTransactions"
                    @search="searchRechargeTransactions"
                    :isParentRequestor="true"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                @click.native="grantsPanelClicked"
                class="mb-4"
              >
                <v-expansion-panel-header
                  >{{ subResellerType }} MFCredit Grants
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ResellerCreditGrants
                    :isLoading="!grantTableLoaded"
                    :grants="subResellerGrants"
                    @pageChanged="grantTablePageChanged"
                    :page="grantTablePage"
                    :totalPages="grantTableTotalPages"
                    title="MFCredit Grants"
                    @download="downloadGrantTransactions"
                    @search="searchGrantTransactions"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                @click.native="paymentsPanelClicked"
                class="mb-4"
              >
                <v-expansion-panel-header
                  >{{ subResellerType }} MFCredit Purchases
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ResellerPayments
                    :isLoading="!paymentTableLoaded"
                    :payments="subResellerPayments"
                    @pageChanged="paymentTablePageChanged"
                    :page="paymentTablePage"
                    :totalPages="paymentTableTotalPages"
                    title="Purchases"
                    @download="downloadPaymentTransactions"
                    @search="searchPaymentTransactions"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                @click.native="playerNetworkPanelClicked"
                class="mb-4"
              >
                <v-expansion-panel-header
                  >{{ subResellerType }} Referrals
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <PlayerNetworkTable
                    :isLoading="!playerNetworkLoaded"
                    :players="playerNetworkArr"
                    @pageChanged="playerNetworkPageChanged"
                    :page="playerNetworkPage"
                    :totalPages="playerNetworkTotalPages"
                    title="Referred Player Network"
                    @search="searchPlayerNetwork"
                    @download="downloadPlayerNetwork"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import "@/assets/css/reseller.css";
import ActionButton from "../components/ActionButton";
import ResellerHeader from "../components/ResellerHeader";
import ResellerPlayerTransactions from "@/components/ResellerPlayerTransactions";
import ResellerCreditGrants from "@/components/ResellerCreditGrants";
import ResellerPayments from "@/components/ResellerPayments";
import PlayerNetworkTable from "@/components/PlayerNetworkTable";
import * as client from "@/client/webclient";
import * as PaymentClient from "@/client/PaymentClient";
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "@/utils/SageUtils";
export default {
  name: "SubReseller",
  components: {
    ActionButton,
    ResellerHeader,
    ResellerPlayerTransactions,
    ResellerCreditGrants,
    ResellerPayments,
    PlayerNetworkTable,
  },

  data() {
    return {
      creditsToTransfer: Number,
      credits: 0,
      isLoading: true,

      rechargeTableLoaded: false,
      playerRecharges: [],
      rechargeTablePage: 1,
      rechargeTableTotalPages: 0,

      grantTableLoaded: false,
      subResellerGrants: [],
      grantTablePage: 1,
      grantTableTotalPages: 0,

      paymentTableLoaded: false,
      subResellerPayments: [],
      paymentTablePage: 1,
      paymentTableTotalPages: 0,

      playerNetworkLoaded: false,
      playerNetworkArr: [],
      playerNetworkPage: 1,
      playerNetworkTotalPages: 0,

      subResellerName: "",
      subResellerPhoneNumber: "",
      subResellerDob: "",
      subResellerCredits: 0,
      subResellerStatus: "PENDING",
      subResellerType: "",
    };
  },
  computed: {
    walletCredits: function () {
      if (!this.credits) {
        return 0;
      }
      return this.credits.toFixed(2);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const creditData = await client.getMoonfrogCredits(
          this.$store.state.account.email,
          this.$store.state.account.email
        );
        this.credits = creditData;

        const subResellerData = await client.getReseller(
          this.$route.params.email,
          this.$store.state.account.email
        );
        this.subResellerName = subResellerData.name;
        this.subResellerPhoneNumber = subResellerData.user.phoneNumber;
        this.subResellerDob = subResellerData.dateOfBirth;
        this.subResellerStatus = subResellerData.user.status;
        this.subResellerType = subResellerData.user.userType;

        if (
          this.subResellerStatus === SageConstants.resellerConstants.ACTIVE ||
          this.subResellerStatus === SageConstants.resellerConstants.DEACTIVATED
        )
          this.subResellerCredits = await client.getMoonfrogCredits(
            this.$route.params.email,
            this.$store.state.account.email
          );
        if (this.subResellerCredits)
          this.subResellerCredits = this.subResellerCredits.toFixed(2);
        else this.subResellerCredits = 0;
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    // Transfer Button
    async transferMfCredits() {
      if (isNaN(this.creditsToTransfer) || this.creditsToTransfer <= 0) {
        alert("Invalid Amount");
        return;
      }

      let subResellerId = this.$route.params.email;

      this.$swal.fire({
        title: "Are you sure?",
        html: this.getConfirmationText(
          subResellerId,
          this.subResellerName,
          this.creditsToTransfer
        ),
        icon: "warning",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          this.$swal.getCancelButton().setAttribute("hidden", true);
          return client
            .grantMfcredits(
              this.$route.params.email,
              this.creditsToTransfer,
              SageConstants.userTypeConstants.RESELLER,
              `parent to ${this.subResellerType} transfer`,
              this.$store.state.account.email
            )
            .then((data) => {
              this.$swal
                .fire({
                  icon: "success",
                  text: "MF credits successfully transferred",
                  confirmButtonText: "OK",
                  showCancelButton: false,
                })
                .then(() => {
                  this.$router.go();
                });
            })
            .catch((error) => {
              this.showConnectionErrorDialog(error);
            });
        },
      });
    },

    // Sub-Reseller Transactions Table
    async rechargesPanelClicked() {
      if (this.rechargeTableLoaded || this.isLoading) return;
      this.isLoading = true;
      this.rechargeTablePage = 1;
      try {
        let txnData = await client.getResellerPlayerTransactions(
          this.$route.params.email,
          this.rechargeTablePage,
          this.$store.state.account.email
        );

        this.playerRecharges = txnData.txns;
        this.rechargeTableTotalPages = txnData.totalPages;
        this.rechargeTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async rechargeTablePageChanged(queryData) {
      this.isLoading = true;
      this.rechargeTableLoaded = false;
      this.rechargeTablePage = queryData.pageNo;
      try {
        let txnData = await client.getResellerPlayerTransactions(
          this.$route.params.email,
          this.rechargeTablePage,
          this.$store.state.account.email,
          this.getDateRangeObj(queryData.dates)
        );

        this.playerRecharges = txnData.txns;
        this.rechargeTableTotalPages = txnData.totalPages;
        this.rechargeTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async searchRechargeTransactions(dates) {
      this.isLoading = true;
      this.rechargeTableLoaded = false;
      this.rechargeTablePage = 1;
      try {
        let txnData = await client.getResellerPlayerTransactions(
          this.$route.params.email,
          this.rechargeTablePage,
          this.$store.state.account.email,
          this.getDateRangeObj(dates)
        );
        this.playerRecharges = txnData.txns;
        this.rechargeTableTotalPages = txnData.totalPages;
        this.rechargeTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadRechargeTransactions(dateRange) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          this.$route.params.email,
          this.$store.state.account.email,
          "recharge",
          this.getDateRangeObj(dateRange)
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    // Sub-Reseller MFCredits Grants Table
    async grantsPanelClicked() {
      if (this.grantTableLoaded || this.isLoading) return;

      this.isLoading = true;
      this.grantTablePage = 1;
      let dates = [
        SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
        SageUtils.getDateFromToday(0),
      ];
      try {
        let grantData = await client.grantHistory(
          this.grantTablePage,
          this.$store.state.account.email,
          "PARENT_RESELLER",
          this.$route.params.email,
          this.getDateRangeObj(dates)
        );

        this.subResellerGrants = grantData.txns;
        this.grantTableTotalPages = grantData.totalPages;
        this.grantTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async grantTablePageChanged(queryData) {
      this.isLoading = true;
      this.grantTableLoaded = false;
      this.grantTablePage = queryData.pageNo;
      try {
        let grantData = await client.grantHistory(
          this.grantTablePage,
          this.$store.state.account.email,
          "PARENT_RESELLER",
          this.$route.params.email,
          this.getDateRangeObj(queryData.dates)
        );

        this.subResellerGrants = grantData.txns;
        this.grantTableTotalPages = grantData.totalPages;
        this.grantTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async searchGrantTransactions(dates) {
      this.isLoading = true;
      this.grantTableLoaded = false;
      this.grantTablePage = 1;
      try {
        let grantData = await client.grantHistory(
          this.grantTablePage,
          this.$store.state.account.email,
          "PARENT_RESELLER",
          this.$route.params.email,
          this.getDateRangeObj(dates)
        );
        this.subResellerGrants = grantData.txns;
        this.grantTableTotalPages = grantData.totalPages;
        this.grantTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadGrantTransactions(dateRange) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          this.$route.params.email,
          this.$store.state.account.email,
          "grant",
          this.getDateRangeObj(dateRange)
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    // Sub-Reseller MFCredit Purchases Table
    async paymentsPanelClicked() {
      if (this.paymentTableLoaded || this.isLoading) return;

      this.isLoading = true;
      this.paymentTablePage = 1;
      let dates = [
        SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
        SageUtils.getDateFromToday(0),
      ];
      try {
        let paymentData =
          await PaymentClient.getResellerCreditPurchaseTransactions(
            this.$route.params.email,
            this.paymentTablePage,
            this.$store.state.account.email,
            this.getDateRangeObj(dates)
          );

        this.subResellerPayments = paymentData.txns;
        this.paymentTableTotalPages = paymentData.totalPages;
        this.paymentTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async paymentTablePageChanged(queryData) {
      this.isLoading = true;
      this.paymentTableLoaded = false;
      this.paymentTablePage = queryData.pageNo;
      try {
        let paymentData =
          await PaymentClient.getResellerCreditPurchaseTransactions(
            this.$route.params.email,
            this.paymentTablePage,
            this.$store.state.account.email,
            this.getDateRangeObj(queryData.dates)
          );

        this.subResellerPayments = paymentData.txns;
        this.paymentTableTotalPages = paymentData.totalPages;
        this.paymentTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async searchPaymentTransactions(dates) {
      this.isLoading = true;
      this.paymentTableLoaded = false;
      this.paymentTablePage = 1;
      try {
        let paymentData =
          await PaymentClient.getResellerCreditPurchaseTransactions(
            this.$route.params.email,
            this.paymentTablePage,
            this.$store.state.account.email,
            this.getDateRangeObj(dates)
          );
        this.subResellerPayments = paymentData.txns;
        this.paymentTableTotalPages = paymentData.totalPages;
        this.paymentTableLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadPaymentTransactions(dateRange) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          this.$route.params.email,
          this.$store.state.account.email,
          "payment",
          this.getDateRangeObj(dateRange)
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    // Sub-Reseller Referrals Table
    async playerNetworkPanelClicked() {
      if (this.playerNetworkLoaded || this.isLoading) return;

      this.isLoading = true;
      this.playerNetworkPage = 1;
      try {
        let playersData = await client.getPlayers(
          "",
          this.$route.params.email,
          0,
          this.$store.state.account.email,
          this.playerNetworkPage
        );

        this.playerNetworkArr = playersData.playersData;
        this.playerNetworkLoaded = true;
        this.playerNetworkTotalPages = playersData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async playerNetworkPageChanged(queryData) {
      this.isLoading = true;
      this.playerNetworkLoaded = false;
      this.playerNetworkPage = queryData.pageNo;
      try {
        let playersData = await client.getPlayers(
          "",
          this.$route.params.email,
          queryData.gameId,
          this.$store.state.account.email,
          this.playerNetworkPage
        );

        this.playerNetworkArr = playersData.playersData;
        this.playerNetworkLoaded = true;
        this.playerNetworkTotalPages = playersData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async searchPlayerNetwork(queryData) {
      this.isLoading = true;
      this.playerNetworkLoaded = false;
      this.playerNetworkPage = 1;
      try {
        let playersData = await client.getPlayers(
          "",
          this.$route.params.email,
          queryData.gameId,
          this.$store.state.account.email,
          this.playerNetworkPage
        );
        this.playerNetworkArr = playersData.playersData;
        this.playerNetworkLoaded = true;
        this.playerNetworkTotalPages = playersData.totalPages;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadPlayerNetwork(queryData) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          this.$route.params.email,
          this.$store.state.account.email,
          "playerNetwork",
          {},
          queryData.gameId
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    // Deactivate Button
    async requestDeactivation() {
      let reason = "";
      const that = this;
      this.$swal
        .fire({
          title: "Write the reason for deactivation.",
          input: "text",
          showCancelButton: true,
          inputAttributes: {
            maxlength: 255,
            autocapitalize: "off",
            autocorrect: "off",
          },
          inputValidator: (value) => {
            if (!value.trim()) {
              return "The reason can't be empty!";
            }
          },
        })
        .then(async function (result) {
          reason = result.value.trim();
          that.isLoading = true;
          try {
            await client.requestResellerDeactivation(
              that.$route.params.email,
              that.$store.state.account.email,
              reason
            );
            that.$swal.fire({
              icon: "success",
              text: `${this.subResellerType} Deactivation request submitted successfully! We will send an Email regarding the action taken.`,
            });
          } catch (e) {
            that.showConnectionErrorDialog(e);
          } finally {
            that.isLoading = false;
          }
        });
    },

    // Extras
    convertToInteger(e) {
      this.creditsToTransfer = parseInt(e.target.value);
    },
    getConfirmationText(subResellerId, subResellerName, credits) {
      const content = document.createElement("p");
      content.style.cssText = "word-break:break-all";

      content.innerHTML = `Confirm MF Credits Transfer <br><br><b><u>${this.subResellerType} Details:</u><b>`;
      if (subResellerName) {
        content.innerHTML += "<br>Name: <b>" + subResellerName + "</b>";
      }
      content.innerHTML +=
        "<br>Email: <b>" +
        subResellerId +
        "</b><br>Credits To Transfer: <b>" +
        credits;
      return content;
    },
    getStatusColor(status) {
      if (status === SageConstants.resellerConstants.ACTIVE) return "green";
      else if (status === SageConstants.resellerConstants.DEACTIVATED)
        return "orange";
      else if (status === SageConstants.resellerConstants.PENDING)
        return "brown";
      else return "red";
    },
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
    getDateRangeObj(dateRange) {
      if (!dateRange) return {};
      if (dateRange.length === 1)
        return { from: dateRange[0], to: dateRange[0] };
      else if (dateRange.length === 2)
        return { from: dateRange[0], to: dateRange[1] };
      else return {};
    },
  },
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 60%;
}
#deactivate-btn {
  background-color: #dc3545;
  border-color: #dc3545;
}
</style>